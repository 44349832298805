// style-manager.service.ts
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StyleManagerService {
  private styleElement: HTMLStyleElement;
  private addedClasses: Set<string> = new Set();

  constructor() {
    this.styleElement = document.createElement('style');
    document.head.appendChild(this.styleElement);
  }

  addColorClass(colorCode: string): string {
    const className = `color-${colorCode.replace('#', '')}`;
    if (this.addedClasses.has(className)) {
      // Sınıf zaten eklendi
      return className;
    }
    const css = `.${className} { color: ${colorCode}; }`;
    this.styleElement.appendChild(document.createTextNode(css));
    this.addedClasses.add(className);
    return className;
  }
}