/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Route } from '@angular/router';
import { LayoutComponent } from 'app/layout/layout.component';
import { InitialDataResolver } from 'app/app.resolvers';
import { AuthGuard } from './core/services/auth/guards/auth.guard';
import { IGuardPermission } from './core/services/auth/model/guardPermission.interface';

// @formatter:off
// tslint:disable:max-line-length
export const appRoutes: Route[] = [
    { path: 'dashboards/home', pathMatch: 'full', redirectTo: '/reports/tracking' },
    { path: '', pathMatch: 'full', redirectTo: '/auth' },
    {
        path: 'auth', component: LayoutComponent,
        //  canActivate: [NoAuthGuard],
        //  canActivateChild: [NoAuthGuard],
        data: { layout: 'empty' },
        children: [
            { path: '', loadChildren: () => import('app/modules/login/login-module.module').then((m: any) => m.LoginModule) },
            { path: 'sign-out', loadChildren: () => import('app/modules/login/sign-out/sign-out.module').then(m => m.AuthSignOutModule) },
            { path: 'forgot-password', loadChildren: () => import('app/modules/login/forgot-password/forgot-password.module').then(m => m.AuthForgotPasswordModule) },
            { path: 'reset-password', loadChildren: () => import('app/modules/login/reset-password/reset-password.module').then(m => m.AuthResetPasswordModule) },
        ],
    },
 
    {
        path: 'live', component: LayoutComponent,
        canActivateChild: [AuthGuard],
        data: {
            layout: 'empty',
            roles: { menuId: 'dashboards-rtls-live-tracking' } as IGuardPermission
        },
        children: [
            {
                path: '',
                loadChildren: () => import('app/modules/live/live.module').then((m: any) => m.LiveModule),
            },
        ]
    },

    
   

    {
        path: '', component: LayoutComponent,
        resolve: { initialData: InitialDataResolver },
        canActivateChild: [AuthGuard],
        children: [
            {
                path: 'maintenances',
                children: [
                    {
                        path: '', loadChildren: () => import('@app/pages/maintenances/maintenances.module').then(m => m.MaintenancesModule)
                    },
                ],
                data: { roles: { menuId: 'maintenances' } as IGuardPermission },
            },

            // {
            //     path: 'dashboards',
            //     children: [
            //         {
            //             path: 'home',
            //             loadChildren: () => import('app/modules/dashboards/dashboards.module').then((m: any) => m.DashboardsModule)
            //         }
            //     ],
            //     data: { roles: { permittedRoles: ['SuperAdmin', 'Admin', 'User', 'Reporter', 'Stock'] } as IGuardPermission },
            // },
            // {
            //     path: 'dashboard-widgets',
            //     children: [
            //         {
            //             path: '',
            //             loadChildren: () => import('app/modules/dashboard-widgets/dashboard-widgets.module').then((m: any) => m.DashboardWidgetsModule),
            //         }
            //     ],
            //     data: { roles: { permittedRoles: ['SuperAdmin', 'Admin', 'User', 'Reporter'] } as IGuardPermission },
            // },
            // {
            //     path: 'dashboard-algida',
            //     children: [
            //         {
            //             path: '',
            //             loadChildren: () => import('app/modules/dashboards/algida/dashboard-algida.module').then(m => m.DashboardAlgidaModule)
            //         },
            //     ],
            //     data: { roles: { permittedRoles: ['SuperAdmin', 'Admin', 'User', 'Reporter', 'Stock'] } as IGuardPermission },
            // },
            // {
            //     path: 'dashboard-teknopalas',
            //     children: [
            //         {
            //             path: '',
            //             loadChildren: () => import('app/modules/dashboards/acibadem/dashboard-acibadem.module').then(m => m.DashboardAcibademModule)
            //         },
            //     ],
            //     data: { roles: { permittedRoles: ['SuperAdmin', 'Admin', 'User', 'Reporter', 'Stock'] } as IGuardPermission },
            // },
            // {
            //     path: 'dashboard-acibadem',
            //     children: [
            //         {
            //             path: '',
            //             loadChildren: () => import('app/modules/dashboards/acibadem-dashboards/acibadem-dashboards.module').then(m => m.AcibademDashboardsModule)
            //         },
            //     ],
            //     data: { roles: { permittedRoles: ['SuperAdmin', 'Admin', 'User', 'Reporter', 'Stock'] } as IGuardPermission },
            // },
            // {
            //     path: 'system-admin-home',
            //     children: [
            //         {
            //             path: '',
            //             //loadChildren: () => import('app/modules/dashboards/acibadem/dashboard-acibadem.module').then(m => m.DashboardAcibademModule)
            //             loadChildren: () => import('app/modules/dashboards/system-admin-home/system-admin-home.module').then(m => m.SystemAdminHomeModule)
            //         },
            //     ],
            //     data: { roles: { permittedRoles: ['SuperAdmin', 'Admin'] } as IGuardPermission },
            // },

            {
                path: 'navizard-search',
                children: [
                    {
                        path: '',
                        loadChildren: () => import('app/modules/search-engine/navizard-search-engine.module').then(m => m.NavizardSearchEngineModule)
                    },
                ],
                data: { roles: { permittedRoles: ['SuperAdmin', 'Admin', 'User', 'Reporter', 'Monitor'] } as IGuardPermission },
            },
            // {
            //     path: 'activities',
            //     children: [
            //         {
            //             path: '',
            //             loadChildren: () => import('app/modules/activity/activity.module').then(m => m.ActivityModule)
            //         },
            //     ],
            //     data: { roles: { permittedRoles: ['SuperAdmin', 'Admin'] } as IGuardPermission },
            // },
            {
                path: 'activity-presets',
                children: [
                    {
                        path: '',
                        loadChildren: () => import('app/modules/activity-preset/activity-preset.module').then(m => m.ActivityPresetModule)
                    },
                ],
                data: { roles: { permittedRoles: ['SuperAdmin', 'Admin'] } as IGuardPermission },
            },
            {
                path: 'activity-locations',
                children: [
                    {
                        path: '',
                        loadChildren: () => import('app/modules/activity-locations/activity-locations.module').then(m => m.ActivityLocationsModule)
                    },
                ],
                data: { roles: { permittedRoles: ['SuperAdmin', 'Admin'] } as IGuardPermission },
            },
            {
                path: 'all-tags',
                children: [
                    {
                        path: '',
                        loadChildren: () => import('app/modules/tags/tags.module').then(m => m.TagsModule)
                    },
                ],
                data: { roles: { menuId: 'legacy-menu-all-tags' } as IGuardPermission },
            },
            {
                path: 'tags',
                children: [
                    {
                        path: '',
                        loadChildren: () => import('app/pages/tags/tags.module').then(m => m.TagsModule)
                    },
                ],
                data: { roles: { menuId: 'definitions-tags' } as IGuardPermission },
            },
            {
                path: 'all-tag-types',
                children: [
                    {
                        path: '',
                        loadChildren: () => import('app/modules/tag-types/tag-types.module').then(m => m.TagTypesModule)
                    },
                ],
                data: { roles: { menuId: 'legacy-menu-all-tag-types' } as IGuardPermission },
            },
            {
                path: 'tag-types',
                children: [
                    {
                        path: '',
                        loadChildren: () => import('app/pages/tag-types/tag-types.module').then(m => m.TagTypesModule)
                    },
                ],
                data: { roles: { menuId: 'configurations-configuration-tag-types' } as IGuardPermission },
            },
            {
                path: 'tag-properties',
                children: [
                    {
                        path: '',
                        loadChildren: () => import('app/pages/tag-properties/tag-properties.module').then(m => m.TagPropertiesModule)
                    },
                ],
                data: { roles: { menuId: 'configurations-configuration-tag-properties' } as IGuardPermission },
            },
            {
                path: 'all-users',
                children: [
                    {
                        path: '',
                        loadChildren: () => import('app/modules/administration/users/users.module').then(m => m.UsersModule)
                    },
                ],
                data: { roles: { menuId: 'legacy-menu-all-administration-users' } as IGuardPermission },
            },
            {
                path: 'all-roles',
                children: [
                    {
                        path: '',
                        loadChildren: () => import('app/modules/administration/roles/roles.module').then(m => m.RolesModule)
                    },
                ],
                data: { roles: { menuId: 'legacy-menu-all-administration-roles' } as IGuardPermission },
            },

            {
                path: 'roles',
                children: [
                    {
                        path: '',
                        loadChildren: () => import('app/pages/roles/roles.module').then(m => m.RolesModule)
                    },
                ],
                data: { roles: { menuId: 'configurations-administration-administration-roles' } as IGuardPermission },
            },
            {
                path: 'settings',
                children: [
                    {
                        path: '',
                        loadChildren: () => import('app/modules/settings/settings.module').then(m => m.SettingsModule)
                    },
                ],
                data: { roles: { menuId: 'configurations-administration-administration-settings' } as IGuardPermission },
            },
            {
                path: 'object-item-tracking',
                children: [
                    {
                        path: '',
                        data: { dynamic:true },
                        loadChildren: () => import('app/modules/object-item-tracking/object-item-tracking.module').then(m => m.ObjectItemTrackingModule)
                    },
                ],
                data: {dynamic:true, roles: { permittedRoles: ['SuperAdmin', 'Admin', 'Stock'] } as IGuardPermission },
            },
            {
                path: 'object-relations',
                children: [
                    {
                        path: '',
                        loadChildren: () => import('app/modules/object-relations/object-relations.module').then(m => m.ObjectRelationsModule)
                    },
                ],
                data: { roles: { permittedRoles: ['SuperAdmin', 'Admin', 'Monitor'] } as IGuardPermission },
            },



            {
                path: 'object-safeties',
                children: [
                    {
                        path: '',
                        loadChildren: () => import('app/pages/object-safeties/object-safeties.module').then(m => m.ObjectSafetiesModule)
                    },
                ],
                data: { roles: { menuId: 'definitions-parent-relations' } as IGuardPermission },
            },

            {
                path: 'live-tag-locations',
                children: [
                    {
                        path: '',
                        loadChildren: () => import('app/pages/live-tag-locations/live-tag-locations.module').then(m => m.LiveTagLocationsModule)
                    },
                ],
                data: { roles: { menuId: 'live-tag-location' } as IGuardPermission },
            },

            {
                path: 'live-tag-properties',
                children: [
                    {
                        path: '',
                        loadChildren: () => import('app/pages/live-tag-properties/live-tag-properties.module').then(m => m.LiveTagPropertiesModule)
                    },
                ],
                data: { roles: { menuId: 'live-tag-property' } as IGuardPermission },
            },


            {
                path: 'object-profile',
                children: [
                    {
                        path: '',
                        loadChildren: () => import('app/modules/object-profile/object-profile.module').then(m => m.ObjectProfileModule)
                    },
                ],
                data: { roles: { permittedRoles: ['SuperAdmin', 'Admin', 'Stock', 'Monitor'] } as IGuardPermission },
            },
            {
                path: 'all-object-types',
                children: [
                    {
                        path: '',
                        loadChildren: () => import('app/modules/object-types/object-types.module').then(m => m.ObjectTypesModule)
                    },
                ],
                data: { roles: { menuId:'legacy-menu-all-object-types'} as IGuardPermission },
            },
            {
                path: 'track-item-types',
                children: [
                    {
                        path: '',
                        loadChildren: () => import('app/pages/track-item-types/track-item-types.module').then(m => m.TrackItemTypesModule)
                    },
                ],
                data: { roles: { menuId: 'definitions-tracking-item-types' } as IGuardPermission },
            },
            {
                path: 'track-item-properties',
                children: [
                    {
                        path: '',
                        loadChildren: () => import('app/pages/track-item-properties/track-item-properties.module').then(m => m.TrackItemPropertiesModule)
                    },
                ],
                data: { roles: { menuId: 'configurations-configuration-track-item-properties' } as IGuardPermission },
            },
            {
                path: 'reports/object-relations',
                children: [
                    {
                        path: '',
                        loadChildren: () => import('app/modules/reports/object-relations/report-object-relations.module').then(m => m.ReportObjectRelationsModule)
                    },
                ],
                data: { roles: { permittedRoles: ['SuperAdmin', 'Admin', 'User', 'Stock'] } as IGuardPermission },
            },
            {
                path: 'reports/daily-report',
                children: [
                    {
                        path: '',
                        loadChildren: () => import('app/modules/reports/daily-movements/daily-movements.module').then(m => m.DailyReportsModule)
                    },
                ],
                data: { roles: { permittedRoles: ['SuperAdmin', 'Admin', 'User', 'Reporter', 'Stock'] } as IGuardPermission },
            },
            {
                path: 'reports/instant-report',
                children: [
                    {
                        path: '',
                        loadChildren: () => import('app/modules/reports/instant-movements/instant-movements.module').then(m => m.InstantReportsModule)
                    },
                ],
                data: { roles: { permittedRoles: ['SuperAdmin', 'Admin', 'User', 'Reporter', 'Stock'] } as IGuardPermission },
            },
            {
                path: 'reports/activity-report',
                children: [
                    {
                        path: '',
                        loadChildren: () => import('app/modules/reports/activity-report/activity-report.module').then(m => m.ActivityReportModule)
                    },
                ],
                data: { roles: { permittedRoles: ['SuperAdmin', 'Admin', 'User', 'Reporter', 'Stock'] } as IGuardPermission },
            },
            {
                path: 'reports/asset-reports',
                children: [
                    {
                        path: '',
                        loadChildren: () => import('app/modules/reports/asset-reports/asset-reports.module').then(m => m.AssetReportsModule)
                    },
                ],
                data: { roles: { permittedRoles: ['SuperAdmin', 'Admin', 'User', 'Reporter', 'Stock'] } as IGuardPermission },
            },
            {
                path: 'reports/temperature',
                children: [
                    {
                        path: '',
                        loadChildren: () => import('app/modules/reports/temperature/reports-temperature.module').then(m => m.ReportsTemperatureModule)
                    },
                ],
                data: { roles: { permittedRoles: ['SuperAdmin', 'Admin', 'User', 'Reporter'] } as IGuardPermission },
            },
            {
                path: 'reports/nurse-report',
                children: [
                    {
                        path: '',
                        loadChildren: () => import('app/modules/reports/nurse/nurse-reports.module').then(m => m.NurseReportsModule)
                    },
                ],
                data: { roles: { permittedRoles: ['SuperAdmin', 'Admin', 'User', 'Reporter'] } as IGuardPermission },
            },
            {
                path: 'reports/nurse-activity-report',
                children: [
                    {
                        path: '',
                        loadChildren: () => import('app/modules/reports/nurse-activity/nurse-activity-reports.module').then(m => m.NurseActivityReportsModule)
                    },
                ],
                data: { roles: { permittedRoles: ['SuperAdmin', 'Admin', 'User', 'Reporter'] } as IGuardPermission },
            },
            {
                path: 'reports/baby-nurse-report',
                children: [
                    {
                        path: '',
                        loadChildren: () => import('app/modules/reports/baby-nurse/baby-nurse-reports.module').then(m => m.BabyNurseReportsModule)
                    },
                ],
                data: { roles: { permittedRoles: ['SuperAdmin', 'Admin', 'User', 'Reporter'] } as IGuardPermission },
            },
            {
                path: 'reports/baby-nurse-activity-report',
                children: [
                    {
                        path: '',
                        loadChildren: () => import('app/modules/reports/baby-nurse-activity/baby-nurse-activity-reports.module').then(m => m.BabyNurseActivityReportsModule)
                    },
                ],
                data: { roles: { permittedRoles: ['SuperAdmin', 'Admin', 'User', 'Reporter'] } as IGuardPermission },
            },
            {
                path: 'reports/asset-report',
                children: [
                    {
                        path: '',
                        loadChildren: () => import('@app/modules/reports/asset-report/asset-report.module').then(m => m.AssetReportModule)
                    },
                ],
                data: { roles: { permittedRoles: ['SuperAdmin', 'Admin', 'User', 'Reporter'] } as IGuardPermission },
            },
            {
                path: 'reports/asset-activity-report',
                children: [
                    {
                        path: '',
                        loadChildren: () => import('app/modules/reports/asset-activity/asset-activity-reports.module').then(m => m.AssetActivityReportsModule)
                    },
                ],
                data: { roles: { permittedRoles: ['SuperAdmin', 'Admin', 'User', 'Reporter'] } as IGuardPermission },
            },
            {
                path: 'reports/battery-level-report',
                children: [
                    {
                        path: '',
                        loadChildren: () => import('app/modules/reports/battery-report/battery-level-reports.module').then(m => m.BatteryLevelReportsModule)
                    },
                ],
                data: { roles: { permittedRoles: ['SuperAdmin', 'Admin', 'User', 'Reporter'] } as IGuardPermission },
            },
            {
                path: 'reports/parent-baby-report',
                children: [
                    {
                        path: '',
                        loadChildren: () => import('app/modules/reports/parent-baby/parent-baby-reports.module').then(m => m.ParentBabyReportsModule)
                    },
                ],
                data: { roles: { permittedRoles: ['SuperAdmin', 'Admin', 'User', 'Reporter'] } as IGuardPermission },
            },
            {
                path: 'reports/access',
                children: [
                    {
                        path: '',
                        loadChildren: () => import('app/modules/reports/reports-visitor/reports-visitor.module').then(m => m.ReportsVisitorModule)
                    },
                ],
                data: { roles: { permittedRoles: ['SuperAdmin', 'Admin', 'User', 'Reporter'] } as IGuardPermission },
            },
            {
                path: 'reports/battery',
                children: [
                    {
                        path: '',
                        loadChildren: () => import('app/modules/reports/reports-battery/reports-battery.module').then(m => m.ReportsBatteryModule)
                    },
                ],
                data: { roles: { permittedRoles: ['SuperAdmin', 'Admin', 'User', 'Reporter'] } as IGuardPermission },
            },
            {
                path: 'reports/history',
                children: [
                    {
                        path: '',
                        loadChildren: () => import('app/modules/reports/report-history/report-history.module').then(m => m.ReportHistoryModule)
                    },
                ],
                data: { roles: { permittedRoles: ['SuperAdmin', 'Admin', 'User', 'Reporter'] } as IGuardPermission },
            },
            {
                path: 'reports/raw',
                children: [
                    {
                        path: '',
                        loadChildren: () => import('app/modules/reports/report-access-history/report-access-history.module').then(m => m.ReportAccessHistoryModule)
                    },
                ],
                data: { roles: { permittedRoles: ['SuperAdmin', 'Admin', 'User', 'Reporter'] } as IGuardPermission },
            },
            {
                path: 'reports/tag-item-properties',
                children: [
                    {
                        path: '',
                        loadChildren: () => import('app/modules/reports/tag-item-properties/properties/tag-item-properties.module').then(m => m.TagItemPropertiesModule)
                    },
                ],
                data: { roles: { permittedRoles: ['SuperAdmin', 'Admin', 'User', 'Reporter'] } as IGuardPermission },
            },

            {
                path: 'reports/tag-item-properties-raw',
                children: [
                    {
                        path: '',
                        loadChildren: () => import('app/modules/reports/tag-item-properties/raw/tag-item-properties-raw.module').then(m => m.TagItemPropertiesRawModule)
                    },
                ],
                data: { roles: { permittedRoles: ['SuperAdmin', 'Admin', 'User', 'Reporter'] } as IGuardPermission },
            },
            {
                path: 'reports/custom',
                children: [
                    {
                        path: '',
                        loadChildren: () => import('app/modules/reports/custom-reports/custom-reports.module').then(m => m.CustomReportsModule)
                    },
                ],
                data: { roles: { permittedRoles: ['SuperAdmin', 'Admin', 'User', 'Reporter'] } as IGuardPermission },
            },
            {
                path: 'reports/movement',
                children: [
                    {
                        path: '',
                        loadChildren: () => import('app/modules/reports/report-movement/report-movement.module').then(m => m.ReportMovementModule)
                    },
                ],
                data: { roles: { permittedRoles: ['SuperAdmin', 'Admin', 'User', 'Reporter'] } as IGuardPermission },
            },
            {
                path: 'buildings',
                children: [
                    {
                        path: '', loadChildren: () => import('@app/pages/buildings/buildings.module').then(m => m.BuildingsModule)
                    },
                ],
                data: { roles: { menuId: 'configurations-configuration-locations-building' } as IGuardPermission },
            },
            {
                path: 'readers-tree',
                children: [
                    {
                        path: '', loadChildren: () => import('app/modules/readers-tree/readers-tree.module').then(m => m.ReadersTreeModule)
                    },
                ],
                data: { roles: { permittedRoles: ['SuperAdmin', 'Admin', 'User'] } as IGuardPermission },
            },
            {
                path: 'floors',
                children: [
                    {
                        path: '', loadChildren: () => import('app/pages/floors/floors.module').then(m => m.FloorsModule)
                    },
                ],
                data: { roles: { menuId: 'configurations-configuration-locations-floor' } as IGuardPermission },
            },
            {
                path: 'all-floors',
                children: [
                    {
                        path: '', loadChildren: () => import('app/modules/floors/floors.module').then(m => m.FloorsModule)
                    },
                ],
                data: { roles: { menuId: 'legacy-menu-all-floor' } as IGuardPermission },
            },
            {
                path: 'readers',
                children: [
                    {
                        path: '',
                        loadChildren: () => import('app/pages/readers/readers.module').then(m => m.ReadersModule)
                    },
                ],
                data: { roles: { menuId: 'configurations-configuration-locations-reader' } as IGuardPermission },
            },
            {
                path: 'all-readers',
                children: [
                    {
                        path: '',
                        loadChildren: () => import('app/modules/readers/readers.module').then(m => m.ReadersModule)
                    },
                ],
                data: { roles: { menuId: 'legacy-menu-all-reader' } as IGuardPermission },
            },
            {
                path: 'all-reader-antennas',
                children: [
                    {
                        path: '',
                        loadChildren: () => import('app/modules/reader-antenna/reader-antenna.module').then(m => m.ReaderAntennaModule)
                    },
                ],
                data: { roles: { menuId: 'legacy-menu-all-reader-antenna' } as IGuardPermission },
            },
            {
                path: 'reader-antennas',
                children: [
                    {
                        path: '',
                        loadChildren: () => import('app/pages/reader-antennas/reader-antennas.module').then(m => m.ReaderAntennasModule)
                    },
                ],
                data: { roles: { menuId: 'configurations-configuration-locations-reader-antenna' } as IGuardPermission },
            },
            {
                path: 'all-groups',
                children: [
                    {
                        path: '',
                        loadChildren: () => import('app/modules/groups/groups.module').then(m => m.GroupsModule)
                    },
                ],
                data: { roles: { menuId: 'legacy-menu-all-group' } as IGuardPermission },
            },
            {
                path: 'groups',
                children: [
                    {
                        path: '',
                        loadChildren: () => import('app/pages/groups/groups.module').then(m => m.GroupsModule)
                    },
                ],
                data: { roles: { menuId: 'configurations-group' } as IGuardPermission },
            },
            {
                path: 'all-zones',
                children: [
                    {
                        path: '',
                        loadChildren: () => import('app/modules/zones/zones.module').then(m => m.ZonesModule)
                    },
                ],
                data: { roles: { menuId: 'legacy-menu-all-zone' } as IGuardPermission },
            },
            {
                path: 'zones',
                children: [
                    {
                        path: '',
                        loadChildren: () => import('app/pages/zones/zones.module').then(m => m.ZonesModule)
                    },
                ],
                data: { roles: { menuId: 'configurations-configuration-locations-zone' } as IGuardPermission },
            },
            {
                path: 'gates',
                children: [
                    {
                        path: '',
                        loadChildren: () => import('app/pages/gates/gates.module').then(m => m.GatesModule)
                    },
                ],
                data: { roles: { menuId: 'configurations-configuration-locations-gate' } as IGuardPermission },
            },
            {
                path: 'all-gates',
                children: [
                    {
                        path: '',
                        loadChildren: () => import('app/modules/gates/gates.module').then(m => m.GatesModule)
                    },
                ],
                data: { roles: { menuId: 'configurations-configuration-locations-gate' } as IGuardPermission },
            },
            {
                path: 'gate-directions',
                children: [
                    {
                        path: '',
                        loadChildren: () => import('app/pages/gate-directions/gate-directions.module').then(m => m.GateDirectionsModule)
                    },
                ],
                data: { roles: { menuId: 'configurations-configuration-locations-gate-directions' } as IGuardPermission },
            },
            {
                path: 'all-gate-direction',
                children: [
                    {
                        path: '',
                        loadChildren: () => import('app/modules/gate-direction/gate-direction.module').then(m => m.GateDirectionModule)
                    },
                ],
                data: { roles: { menuId: 'legacy-menu-all-gate-direction' } as IGuardPermission },
            },
            {
                path: 'access-rule',
                children: [
                    {
                        path: '',
                        loadChildren: () => import('app/modules/access-rules/access-rules.module').then(m => m.AccessRulesModule)
                    },
                ],
                data: { roles: { permittedRoles: ['SuperAdmin', 'Admin'] } as IGuardPermission },
            },
            {
                path: 'style-rule',
                children: [
                    {
                        path: '',
                        loadChildren: () => import('app/modules/style-rules/style-rules.module').then(m => m.StyleRulesModule)
                    },
                ],
                data: { roles: { permittedRoles: ['SuperAdmin', 'Admin'] } as IGuardPermission },
            },

            // Reports
            {
                path: 'reports',
                children: [
                    {
                        path: '',
                        loadChildren: () => import('app/reports/reports.module').then(m => m.ReportsModule)
                    },
                ],
                data: { roles: { menuId: 'reports' } as IGuardPermission },
            },


            {
                path: 'notifications',
                children: [
                    {
                        path: '', loadChildren: () => import('@app/pages/notifications/notifications.module').then(m => m.NotificationsModule)
                    },
                ],
                data: { roles: { menuId: 'configurations-notifications' } as IGuardPermission },
            },

            {
                path: 'mqtt',
                children: [
                    {
                        path: '', loadChildren: () => import('@app/pages/mqtt/mqttTest.module').then(m => m.MqttTestModule)
                    },
                ],
                data: { roles: { menuId: undefined } as IGuardPermission },
            },


        ],
    },
    {
        path: 'accessdenied',
        component: LayoutComponent,
        resolve: { initialData: InitialDataResolver },
        children: [
            {
                path: '',
                loadChildren: () => import('app/modules/access-denied/access-denied.module').then(m => m.AccessDeniedModule)
            },
        ],
    },
    { path: '**', redirectTo: 'accessdenied#path_not_found' },

];
