import { Injectable } from "@angular/core";
import { MaintenanceHubConnectionService } from "@app/core/services/hubs/maintenance-hub-connection.service";
import { Maintenance } from "@app/core/services/hubs/models/maintenance.model";
import { AlertValidationResult, TagAlert } from "@app/core/services/hubs/models/tag-alert.model";
import { PostOfficeService } from "@app/core/services/post-office/post-office.service";
import { SettingsService } from "@app/core/services/settings/settings.service";
import { AlertType, SecurityAlert } from "@app/shared/toasts/security-alert/models/security-alert";
import { SecurityAlertComponent } from "@app/shared/toasts/security-alert/security-alert.component";
import { TranslateService } from "@ngx-translate/core";
import { cloneDeep } from "lodash";
import { GlobalConfig, ToastrService } from "ngx-toastr";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class ToastNotifyService {

    // Bildirim kuyruğu ve sessize alma ayarları
    private alertQueue: TagAlert[] = [];
    private muteDuration = 60; // Sessize alma süresi saniye olarak (örnek: 60 saniye)
    private countdown$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    private countdownInterval: any;
    private _isMuted: boolean;
    // Sessize alma durumunu getter ve setter ile kontrol et
    get isMuted(): boolean {
        return this._isMuted;
    }

    set isMuted(value: boolean) {
        this._isMuted = value;
        this.toggleMuteCountdown(value);
    }

    constructor(public toastr: ToastrService,
        private translateService: TranslateService,
        private maintenanceHub: MaintenanceHubConnectionService,
        private postOfficeService: PostOfficeService,
        private settingsService: SettingsService,) { }

    public setToastContainer(container: any) {
        // ToastrService içinde özel konteyneri tanımlayın
        this.toastr.overlayContainer = container;
    }

  
    async clearNotifications(delay: number = 300, smoothClear: boolean = true) {
        if (!smoothClear) {
            this.clearNotifications();
            return;
        }
        
        const activeToasts = this.toastr.toasts.slice(); // Create a copy of the active toasts array
        for (const toast of activeToasts) {
            this.toastr.remove(toast.toastId);
            await new Promise(resolve => setTimeout(resolve, delay));
        }
 
    }

    toggleMute() {
        this.isMuted = !this.isMuted;
    }

    public get activeNotifications(): number {
        return this.toastr.currentlyActive;
    }


    showMaintenanceAlert(data: Maintenance.FragmentationProgress) {
        let options: GlobalConfig;
        options = this.toastr.toastrConfig;
        const opt = cloneDeep(options);
        opt.toastComponent = SecurityAlertComponent;
        opt.toastClass = 'toast';
        opt.enableHtml = false;
        opt.disableTimeOut = true;
        opt.tapToDismiss = false;
        opt.countDuplicates = false;
        opt.preventDuplicates = true;
        opt.closeButton = true;
        opt.positionClass = 'inline';


        const sa = new SecurityAlert();
        sa.date = data.timestamp;
        sa.alertType = AlertType.Info;
        sa.detailsUrl = '/maintenances/database';
        sa.title = 'Database Optimization in Progress';

        sa.content = `
   <p class="text-gray-700">
     You may experience <span class="underline decoration-2 decoration-sky-500 font-bold">temporary slowdowns</span> until the process is complete.
   </p>
   `;

        const toastRef = this.toastr.show(JSON.stringify(sa), '', opt);

        // Pass the currentProgress dynamically to the toast component
        if (toastRef.toastRef.componentInstance) {
            this.maintenanceHub.fragmentationProgress$.subscribe((md) => {
                toastRef.toastRef.componentInstance.progress = md.progress;
                if (md.progress == 100) {
                    const alertInstance = toastRef.toastRef.componentInstance.alert;

                    alertInstance.alertType = AlertType.Success;
                    alertInstance.title = 'Database Optimization Completed';
                    alertInstance.content = `
          <div class="text-gray-700">
            <span>Your database has been <span class="underline decoration-2 decoration-green-500 font-bold">successfully optimized</span>. Please note that the system may experience temporary slowdowns as the changes take effect. This is expected and should resolve shortly as the system stabilizes.</span>
          </div>`;

                    //püf noktası
                    toastRef.toastRef.componentInstance.alert = alertInstance;
                    //dismiss dialog after 10 seconds
                    setTimeout(() => {
                        this.toastr.clear(toastRef.toastId);
                    }, 15000);

                }
            });
        }

    }


    // Geri sayımı başlatır veya durdurur
    private toggleMuteCountdown(isMuted: boolean) {
        if (isMuted) {
            this.clearNotifications();
            this.countdown$.next(this.muteDuration);
            this.countdownInterval = setInterval(() => {
                const current = this.countdown$.value;
                if (current > 0) {
                    this.countdown$.next(current - 1);
                } else {
                    this.clearMuteCountdown();
                    this.displayQueuedAlerts();
                }
            }, 1000);
        } else {
            this.clearMuteCountdown();
            this.displayQueuedAlerts();
        }
    }

    // Geri sayım intervalini temizler
    private clearMuteCountdown() {
        clearInterval(this.countdownInterval);
        this._isMuted = false;
        this.countdown$.next(0);
    }

    // Kuyrukta biriken bildirimleri sırayla gösterir
    private displayQueuedAlerts() {

        setTimeout(async () => {

            while (this.alertQueue.length > 0) {
                const alert = this.alertQueue.shift();
                if (alert) {
                    this.showAlert(alert);
                    await new Promise(resolve => setTimeout(resolve, 250));
                }
            }
        }, 250);

    }

    // Sessize alma süresi boyunca bildirimleri kuyrukta biriktirir
    async showAlert(tagAlert: TagAlert) {
        if (this.isMuted) {
            this.alertQueue.push(tagAlert);
            return;
        }

        // Normal gösterim kodları burada devam ediyor...
        var tag = await this.postOfficeService.getTag(tagAlert.tagId, undefined);

        if (tag.trackItemId == null) return;

        var trackItem = await this.postOfficeService.getTrackItem(tag.trackItemId);
        var infantTagTypeId = this.settingsService.getSettingValue('Infant.Baby.Tag.Type');
        let isInfantTag = tag.tagTypeId == infantTagTypeId;

        let options: GlobalConfig;
        options = this.toastr.toastrConfig;
        const opt = cloneDeep(options);
        opt.toastComponent = SecurityAlertComponent;
        opt.toastClass = 'toast';
        opt.enableHtml = false;
        opt.disableTimeOut = false;
        opt.timeOut = 20000;
        opt.tapToDismiss = true;
        opt.closeButton = true;
        opt.positionClass = 'inline';

        const sa = new SecurityAlert();
        sa.date = tagAlert.readAt;
        sa.photoUrl = trackItem.photoUrl;
        sa.locateUrl = '/live';
        sa.locateUrlParams = { locate: 'tag', id: tagAlert.tagSessionHistoryId };
        sa.title = tagAlert.alert == AlertValidationResult.Alert
            ? this.translateService.instant('SystemAdmin.Alerts.SecurityAlert.Title')
            : this.translateService.instant('SystemAdmin.Alerts.SecurityAlert.Resolved.Title');
        sa.alertType = tagAlert.alert == AlertValidationResult.Alert ? AlertType.Error : AlertType.Success;
        sa.showImage = true;

        if (isInfantTag) {
            sa.detailsUrl = `/object-safeties`;
            sa.detailsUrlParams = { 'child-tag': tagAlert.tagId };
        }

        const message = this.translateService.instant(
            tagAlert.alert == AlertValidationResult.Alert
                ? 'SystemAdmin.Alerts.SecurityAlert.SensorDescription'
                : 'SystemAdmin.Alerts.SecurityAlert.Resolved.SensorDescription',
            {
                sensorProperty: tagAlert.propertyName,
                trackItemDescription: trackItem.description,
                tagName: tag.name
            }
        );

        sa.content = `<p>${message}</p>`;
        this.toastr.show(JSON.stringify(sa), '', opt);
    }

    // Geri sayımı takip eden observable (komponentte göstermek için)
    getCountdown(): Observable<number> {
        return this.countdown$.asObservable();
    }



}

