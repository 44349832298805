import { RouteReuseStrategy, DetachedRouteHandle, ActivatedRouteSnapshot } from '@angular/router';

export class CustomRouteReuseStrategy implements RouteReuseStrategy {

    shouldDetach(route: ActivatedRouteSnapshot): boolean {
        return false; // Depolanmasın
    }

    store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle | null): void {
        // Depolanmasın
    }

    shouldAttach(route: ActivatedRouteSnapshot): boolean {
        return false; // Yeniden bağlanmasın
    }

    retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
        return null; // Her zaman yeni bir örnek oluşturulsun
    }

    shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
        // Eğer mevcut veya gelecekteki rotada `reload: true` bayrağı varsa yeniden kullanım yapılmasın
        const shouldReloadCurrent = curr.data?.['reload'];
        const shouldReloadFuture = future.data?.['reload'];
        if (shouldReloadCurrent || shouldReloadFuture) {
            return false; // `reload` bayrağı varsa bileşen yeniden yüklensin
        }
        // Bayrak yoksa Angular'ın varsayılan davranışı devreye girsin
        return future.routeConfig === curr.routeConfig;
    }
}