import { Injectable } from '@angular/core';
import { BaseHubConnection } from './base-hub-connection.service';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { GlobalVariablesService } from '../global-variables.service';
import { Maintenance } from './models/maintenance.model';
import { Guid } from '@app/core/constants/constants';


@Injectable({
  providedIn: 'root',
})
export class MaintenanceHubConnectionService extends BaseHubConnection {


  private fragmentationSubject = new BehaviorSubject<Maintenance.FragmentationProgress>(null);
  public fragmentationProgress$ = this.fragmentationSubject.asObservable();

  private sqlCpuMetricsSubject = new BehaviorSubject<Maintenance.SqlServerMetrics>(null);
  public sqlCpuMetrics$ = this.sqlCpuMetricsSubject.asObservable();


  private indexInfoSubject = new BehaviorSubject<Maintenance.IndexInfo[]>(null);
  public indexInfo$ = this.indexInfoSubject.asObservable();

  private fragmentationReportSubject = new BehaviorSubject<Maintenance.IndexFragmentationReport[]>(null);
  public fragmentationReport$ = this.fragmentationReportSubject.asObservable();

  public id: Guid = Guid.newGuid();

  public currentProgress: Maintenance.FragmentationProgress;
  public currentHealthScore: Maintenance.DatabaseHealthScore;

  constructor(private globals: GlobalVariablesService) {
    super();
  }

  public init() {
    this.initializeHubConnection(`${this.globals.tracker.address}/maintenance-hub`);

    this.connectionStatus.subscribe((status) => {
     
    });

    this.hubConnection.on('ReceiveMaintenanceStatus', (data: Maintenance.FragmentationProgress) => {
      this.currentProgress = data;


      this.fragmentationSubject.next(data);
    });

    this.hubConnection.on('ReceiveSqlCpuUsage', (data: any) => {
      this.sqlCpuMetricsSubject.next(data);
    });

    //ReceiveIndex

    this.hubConnection.on('ReceiveIndex', (data: Maintenance.IndexInfo[]) => {
      this.indexInfoSubject.next(data);
    });

    //ReceiveFragmentationReport
    this.hubConnection.on('ReceiveFragmentationReport', (data: Maintenance.IndexFragmentationReport[]) => {
      this.fragmentationReportSubject.next(data);
    });
  }

  public triggerReorganizeIndex() {
    this.sendMessage('TriggerReorganizeIndex');
  }

  public triggerRebuildIndex() {
    this.sendMessage('TriggerRebuildIndex');
  }

  public retrieveAllIndexes() {
    this.sendMessage('RetrieveIndexes');
  }

  public retrieveFragmentationReport() {
    this.sendMessage('RetrieveFragmentationReport');
  }

  public getFragmentationReport(): Promise<Maintenance.IndexFragmentationReport[]> {
    return this.hubConnection.invoke<Maintenance.IndexFragmentationReport[]>('GetFragmentationReport');
  }



  public getDatabaseHealthScore(): Promise<Maintenance.DatabaseHealthScore> {
    // Ensure the invoke call is awaited and return the promise
    return this.hubConnection.invoke<Maintenance.DatabaseHealthScore>('GetDatabaseHealthScore')
      .then((result: Maintenance.DatabaseHealthScore) => {
        // Store the result in the currentHealthScore variable
        this.currentHealthScore = result;
        return result; // Return the resolved result
      })
      .catch((error: any) => {
        console.error('Error fetching database health score:', error);
        throw error; // Throw the error to be handled by the calling code
      });
  }



}