<div class="toast m-2 mr-4 pointer-events-auto cursor-pointer rounded-lg bg" [ngClass]="{
    'green': alert.alertType === AlertType.Success,
    'red': alert.alertType === AlertType.Error,
    'blue': alert.alertType === AlertType.Info,
    'yellow': alert.alertType === AlertType.Warning
  }">
    <div class="space-y-5">
        <div
            class="relative mx-auto w-full bg-white/40  backdrop-blur-lg drop-shadow-md rounded-lg border-none ring-2 ring-white/30 p-4 text-sm shadow-lg">

            <!-- Close Button -->
            <button *ngIf="toastPackage.config.closeButton" class="top-3 absolute right-4 ml-auto cursor-pointer text-slate-600 hover:text-white" (click)="dismiss()" >
                <fa-icon [icon]="['fal', 'times']" class="h-5 w-5 text-xl font-bold"></fa-icon>
            </button>

            <div class="flex space-x-4 mr-6">
                <!-- Photo -->
                <div class="flex items-center space-x-4" *ngIf="alert.showImage">
                    <div
                        class="relative h-14 w-14 rounded-full border-2 border-white border-opacity-20  overflow-hidden transition-transform duration-300 hover:scale-125 ease-in-out mr-2 ">
                        <img [src]="imageService.temporaryTrackItemImage(alert.photoUrl)"
                            (error)="imageService.imageError($event)"
                            class="object-cover object-center h-full w-full max-w-xs hover:scale-150  origin-center transition duration-1000 ease-in-out z-0">
                    </div>
                </div>

                <div class="flex-1">
                    <!-- Title and Time -->
                    <div class="flex-col items-start space-y-2">
                        <h4 class="font-medium text-slate-900">{{alert.title ? alert.title
                            :'SystemAdmin.Alerts.SecurityAlert.Title' | translate}}</h4>
                        <span class="font-normal text-xs text-slate-500">{{alert.date | relativeTime }}</span>
                    </div>

                    <!-- Content -->
                    <div class="mt-1 text-slate-800" [innerHTML]="safeHtml">
                       
                    </div>

                    <!-- Slim Progress Bar -->
                    <div class="relative w-full bg-gray-200 rounded-full h-3 mt-4" *ngIf="progress > 0">
                        <!-- Progress bar fill -->
                        <div class="absolute top-0 left-0 h-full bg-blue-500 rounded-full"
                            [style.width]="progress + '%'"></div>

                        <!-- Progress value centered -->
                        <div class="absolute inset-0 flex items-center justify-center">
                            <span class="text-white text-xs font-medium">{{ progress | number:'1.1-1' }}%</span>
                        </div>
                    </div>

                    <!-- Button Group -->
                    <div class="mt-2 flex space-x-4">
                        <a *ngIf="alert.locateUrl" [routerLink]="[alert.locateUrl]"
                            [queryParams]="alert.locateUrlParams"
                            class="inline-block font-medium leading-loose text-slate-500 hover:text-slate-900">
                            {{'SystemAdmin.Alerts.SecurityAlert.CTA.Locate' | translate}}
                        </a>
                        <a *ngIf="alert.detailsUrl" [routerLink]="[alert.detailsUrl]"
                            [queryParams]="alert.detailsUrlParams"
                            class="inline-block font-medium leading-loose text-blue-600 hover:text-blue-700">
                            {{'SystemAdmin.Alerts.SecurityAlert.CTA.Details' | translate}}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>