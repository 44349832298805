/* tslint:disable:max-line-length */

import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] =

[
    {
        id: 'rtls_live_tracking',
        title: 'SYSTEM.LIVETRACKING',
        type: 'basic',
        icon: 'fontawesome_light:map-marked-alt',
        link: '/live',
        roles: ['SuperAdmin', 'Admin', 'Monitor', 'Reporter'],
        hidden: (_: FuseNavigationItem) => false
    },
    {
        id: 'dashboards',
        title: 'DASHBOARD.MENU',
        type: 'collapsable',
        icon: 'fontawesome_light:chart-line',
        roles: ['SuperAdmin', 'Admin', 'Monitor', 'Reporter', 'Users'],
        hidden: (_: FuseNavigationItem) => false,
        children: [
            {
                id: 'dashboards.home',
                title: 'DASHBOARD.HOME',
                type: 'basic',
                link: '/dashboards/home'
            }
        ]
    },
    {
        id: 'custom_object_type',
        title: 'SPECIAL.CUSTOM_OBJECT_TYPES',
        type: 'collapsable',
        icon: 'fontawesome_light:edit',
        hidden: (_: FuseNavigationItem) => false,
        roles: ['SuperAdmin', 'Admin', 'Stock'],
        children: []
    },
    {
        id: 'relations',
        title: 'TRACKITEM.RELATIONS',
        type: 'basic',
        icon: 'fontawesome_light:users',
        hidden: (_: FuseNavigationItem) => false,
        roles: ['SuperAdmin', 'Admin'],
        children: []
    },
    {
        id: 'reports',
        title: 'REPORTS.MENU',
        type: 'collapsable',
        icon: 'fontawesome_light:clipboard',
        hidden: (_: FuseNavigationItem) => false,
        roles: ['SuperAdmin', 'Admin', 'Monitor', 'Reporter'],
        children: [
            {
                id: 'reportsDaily',
                title: 'REPORTS.DAILY_MOVEMENT',
                type: 'basic',
                link: '/reports/daily-report',
            },
            // {
            //     id: 'reportsAsset',
            //     title: 'REPORTS.DAILY_MOVEMENT',
            //     type: 'basic',
            //     link: '/reports/asset-report'
            // },
            {
                id: 'reportsTagItemsProperties',
                title: 'REPORTS.PROPERTIES',
                type: 'basic',
                link: '/reports/tag-item-properties',
            },
            {
                id: 'reportsTagItemsPropertiesRaw',
                title: 'REPORTS.PROPERTIES_RAW',
                type: 'basic',
                link: '/reports/tag-item-properties-raw',
            },
            // {
            //     id: 'reportsAccess',
            //     title: 'REPORTS.RAW',
            //     type: 'basic',
            //     link: '/reports/raw',
            // },
            // {
            //     id: 'customReports',
            //     title: 'REPORTS.CUSTOM.MENU',
            //     type: 'basic',
            //     link: '/reports/custom'
            // },
            // {
            //     id: 'movementReports',
            //     title: 'REPORTS.MOVEMENT_REPORT',
            //     type: 'basic',
            //     link: '/reports/movement'
            // }
        ]
    },
    {
        id: 'definition',
        title: 'DEFINITIONS.MENU',
        type: 'collapsable',
        icon: 'fontawesome_light:edit',
        hidden: (_: FuseNavigationItem) => false,
        roles: ['SuperAdmin', 'Admin'],
        children: [
            {
                'id': 'tags',
                'title': 'TAG.MENU',
                'type': 'basic',
                'link': '/tags'
            },
            {
                'id': 'customReportWidget',
                'title': 'REPORTS.WIDGET.MENU',
                'type': 'basic',
                'link': '/reports/widget'
            },
            {
                'id': 'activities',
                'title': 'ACTIVITIES.MENU',
                'type': 'basic',
                'link': '/activities'
            },
            {
                'id': 'access-rule',
                'title': 'Access Rule',
                'type': 'basic',
                'link': '/access-rule'
            }
        ]
    },
    {
        id: 'configuration',
        title: 'CONFIGURATIONS.MENU',
        type: 'collapsable',
        icon: 'fontawesome_light:cogs',
        hidden: (_: FuseNavigationItem) => false,
        roles: ['SuperAdmin', 'Admin', 'Users'],
        children: [
            {
                id: 'locations',
                title: 'LOCATION.MENU',
                type: 'collapsable',
                hidden: (_: FuseNavigationItem) => false,
                children: [
                    {
                        'id': 'map',
                        'title': 'MAP.MENU',
                        'type': 'basic',
                        'link': '/maps'
                    },
                    {
                        'id': 'building',
                        'title': 'BUILDING.MENU',
                        'type': 'basic',
                        'link': '/buildings'
                    },
                    {
                        'id': 'floor',
                        'title': 'FLOOR.MENU',
                        'type': 'basic',
                        'link': '/floors'
                    },
                    {
                        'id': 'zone',
                        'title': 'ZONE.MENU',
                        'type': 'basic',
                        'link': '/zones'
                    },
                    {
                        'id': 'group',
                        'title': 'GROUP.MENU',
                        'type': 'basic',
                        'link': '/groups'
                    },
                    {
                        'id': 'reader',
                        'title': 'READER.MENU',
                        'type': 'basic',
                        'link': '/readers'
                    },
                    {
                        'id': 'readerAntenna',
                        'title': 'READERANTENNA.MENU',
                        'type': 'basic',
                        'link': '/reader-antennas'
                    },
                    {
                        'id': 'gate',
                        'title': 'GATE.MENU',
                        'type': 'basic',
                        'link': '/gates'
                    },
                    {
                        'id': 'gateDirection',
                        'title': 'GATEDIRECTION.MENU',
                        'type': 'basic',
                        'link': '/gate-direction'
                    }
                ]
            },
            {
                'id': 'tag-types',
                'title': 'TAGTYPES.MENU',
                'type': 'basic',
                'link': '/tag-types'
            },
            {
                'id': 'objectTypes',
                'title': 'OBJECT_TYPE.MENU',
                'type': 'basic',
                'link': '/object-types'
            },
            {
                'id': 'activityTypes',
                'title': 'ACTIVITY_TYPE.MENU',
                'type': 'basic',
                'link': '/activity-presets'
            },
            {
                'id': 'styleRule',
                'title': 'Style Rule',
                'type': 'basic',
                'link': '/style-rule'
            }
        ]
    },
    {
        id: 'administration',
        title: 'ADMINISTRATION_MODULE.MENU',
        type: 'collapsable',
        icon: 'fontawesome_light:users',
        roles: ['SuperAdmin', 'Admin'],
        hidden: (_: FuseNavigationItem) => false,
        children: [
            {
                id: 'administration_users',
                title: 'ADMINISTRATION_MODULE.USERS.TITLE',
                type: 'basic',
                link: '/users'
            }
            // {
            //     id: 'administration_roles',
            //     title: 'ADMINISTRATION_MODULE.ROLES.TITLE',
            //     type: 'basic',
            //     link: '/roles'
            // }
        ]
    }

];

export const compactNavigation: FuseNavigationItem[] = [
    {
        id      : 'dashboards',
        title   : 'Dashboards',
        tooltip : 'Dashboards',
        type    : 'aside',
        icon    : 'heroicons_outline:home',
        children: [] // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id      : 'apps',
        title   : 'Apps',
        tooltip : 'Apps',
        type    : 'aside',
        icon    : 'heroicons_outline:qrcode',
        children: [] // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id      : 'pages',
        title   : 'Pages',
        tooltip : 'Pages',
        type    : 'aside',
        icon    : 'heroicons_outline:document-duplicate',
        children: [] // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id      : 'user-interface',
        title   : 'UI',
        tooltip : 'UI',
        type    : 'aside',
        icon    : 'heroicons_outline:collection',
        children: [] // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id      : 'navigation-features',
        title   : 'Navigation',
        tooltip : 'Navigation',
        type    : 'aside',
        icon    : 'heroicons_outline:menu',
        children: [] // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    }
];

export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id: 'rtls_live_tracking',
        title: 'SYSTEM.LIVETRACKING',
        type: 'basic',
        icon: 'fontawesome_light:map-marked-alt',
        link: '/live',
        roles: ['SuperAdmin', 'Admin', 'Monitor', 'Reporter'],
        hidden: (_: FuseNavigationItem) => false
    },
    {
        id: 'dashboards',
        title: 'DASHBOARD.MENU',
        type: 'collapsable',
        icon: 'fontawesome_light:chart-line',
        roles: ['SuperAdmin', 'Admin', 'Monitor', 'Reporter', 'Users'],
        hidden: (_: FuseNavigationItem) => false,
        children: [
            {
                id: 'dashboards.home',
                title: 'DASHBOARD.HOME',
                type: 'basic',
                link: '/dashboards/home'
            }
        ]
    },
    {
        id: 'custom_object_type',
        title: 'SPECIAL.CUSTOM_OBJECT_TYPES',
        type: 'collapsable',
        icon: 'fontawesome_light:edit',
        hidden: (_: FuseNavigationItem) => false,
        roles: ['SuperAdmin', 'Admin'],
        children: []
    },
    {
        id: 'relations',
        title: 'TRACKITEM.RELATIONS',
        type: 'basic',
        icon: 'fontawesome_light:users',
        hidden: (_: FuseNavigationItem) => false,
        roles: ['SuperAdmin', 'Admin'],
        children: []
    },
    {
        id: 'reports',
        title: 'REPORTS.MENU',
        type: 'collapsable',
        icon: 'fontawesome_light:clipboard',
        hidden: (_: FuseNavigationItem) => false,
        roles: ['SuperAdmin', 'Admin', 'Monitor', 'Reporter'],
        children: [
            {
                id: 'reportsDaily',
                title: 'REPORTS.DAILY_MOVEMENT',
                type: 'basic',
                link: '/reports/daily-report',
            },
            // {
            //     id: 'reportsAsset',
            //     title: 'REPORTS.DAILY_MOVEMENT',
            //     type: 'basic',
            //     link: '/reports/asset-report'
            // },
            {
                id: 'reportsTagItemsProperties',
                title: 'REPORTS.PROPERTIES',
                type: 'basic',
                link: '/reports/tag-item-properties',
            },
            {
                id: 'reportsTagItemsPropertiesRaw',
                title: 'REPORTS.PROPERTIES_RAW',
                type: 'basic',
                link: '/reports/tag-item-properties-raw',
            },
            // {
            //     id: 'reportsAccess',
            //     title: 'REPORTS.RAW',
            //     type: 'basic',
            //     link: '/reports/raw',
            // },
            // {
            //     id: 'customReports',
            //     title: 'REPORTS.CUSTOM.MENU',
            //     type: 'basic',
            //     link: '/reports/custom'
            // },
            // {
            //     id: 'movementReports',
            //     title: 'REPORTS.MOVEMENT_REPORT',
            //     type: 'basic',
            //     link: '/reports/movement'
            // }
        ]
    },
    {
        id: 'definition',
        title: 'DEFINITIONS.MENU',
        type: 'collapsable',
        icon: 'fontawesome_light:edit',
        hidden: (_: FuseNavigationItem) => false,
        roles: ['SuperAdmin', 'Admin'],
        children: [
            {
                'id': 'tags',
                'title': 'TAG.MENU',
                'type': 'basic',
                'link': '/tags'
            },
            {
                'id': 'customReportWidget',
                'title': 'REPORTS.WIDGET.MENU',
                'type': 'basic',
                'link': '/reports/widget'
            },
            {
                'id': 'activities',
                'title': 'ACTIVITIES.MENU',
                'type': 'basic',
                'link': '/activities'
            },
            {
                'id': 'access-rule',
                'title': 'Access Rule',
                'type': 'basic',
                'link': '/access-rule'
            }
        ]
    },
    {
        id: 'configuration',
        title: 'CONFIGURATIONS.MENU',
        type: 'collapsable',
        icon: 'fontawesome_light:cogs',
        hidden: (_: FuseNavigationItem) => false,
        roles: ['SuperAdmin', 'Admin', 'Users'],
        children: [
            {
                id: 'locations',
                title: 'LOCATION.MENU',
                type: 'collapsable',
                hidden: (_: FuseNavigationItem) => false,
                children: [
                    {
                        'id': 'map',
                        'title': 'MAP.MENU',
                        'type': 'basic',
                        'link': '/maps'
                    },
                    {
                        'id': 'building',
                        'title': 'BUILDING.MENU',
                        'type': 'basic',
                        'link': '/buildings'
                    },
                    {
                        'id': 'floor',
                        'title': 'FLOOR.MENU',
                        'type': 'basic',
                        'link': '/floors'
                    },
                    {
                        'id': 'zone',
                        'title': 'ZONE.MENU',
                        'type': 'basic',
                        'link': '/zones'
                    },
                    {
                        'id': 'group',
                        'title': 'GROUP.MENU',
                        'type': 'basic',
                        'link': '/groups'
                    },
                    {
                        'id': 'reader',
                        'title': 'READER.MENU',
                        'type': 'basic',
                        'link': '/readers'
                    },
                    {
                        'id': 'readerAntenna',
                        'title': 'READERANTENNA.MENU',
                        'type': 'basic',
                        'link': '/reader-antennas'
                    },
                    {
                        'id': 'gate',
                        'title': 'GATE.MENU',
                        'type': 'basic',
                        'link': '/gates'
                    },
                    {
                        'id': 'gateDirection',
                        'title': 'GATEDIRECTION.MENU',
                        'type': 'basic',
                        'link': '/gate-direction'
                    }
                ]
            },
            {
                'id': 'tag-types',
                'title': 'TAGTYPES.MENU',
                'type': 'basic',
                'link': '/tag-types'
            },
            {
                'id': 'objectTypes',
                'title': 'OBJECT_TYPE.MENU',
                'type': 'basic',
                'link': '/object-types'
            },
            {
                'id': 'activityTypes',
                'title': 'ACTIVITY_TYPE.MENU',
                'type': 'basic',
                'link': '/activity-presets'
            },
            {
                'id': 'styleRule',
                'title': 'Style Rule',
                'type': 'basic',
                'link': '/style-rule'
            }
        ]
    },
    {
        id: 'administration',
        title: 'ADMINISTRATION_MODULE.MENU',
        type: 'collapsable',
        icon: 'fontawesome_light:users',
        roles: ['SuperAdmin', 'Admin'],
        hidden: (_: FuseNavigationItem) => false,
        children: [
            {
                id: 'administration_users',
                title: 'ADMINISTRATION_MODULE.USERS.TITLE',
                type: 'basic',
                link: '/users'
            }
            // {
            //     id: 'administration_roles',
            //     title: 'ADMINISTRATION_MODULE.ROLES.TITLE',
            //     type: 'basic',
            //     link: '/roles'
            // }
        ]
    }

];
