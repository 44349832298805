import { Component, OnInit } from '@angular/core';
import { BackgroundService } from './background.service';
import { Background } from './models/models';

@Component({
  selector: 'app-background',
  templateUrl: './background.component.html',
  styleUrls: ['./background.component.scss']
})
export class BackgroundComponent implements OnInit {
  currentBackground: Background;
  circles: number[] = [];
  circleStyles: any[] = [];
  shade: boolean = true;
  constructor(public backgroundService: BackgroundService) { }

  ngOnInit(): void {
    this.backgroundService.currentBackground.subscribe(bg => {
      this.currentBackground = bg;

      if (bg.type === 'animated' && bg.animatedStyle) {
        this.generateCircles(bg.animatedStyle.amount);
        this.generateCircleStyles();
      }

    });
  }



  generateCircles(amount: number): void {
    this.circles = Array(amount).fill(0);
  }

  generateCircleStyles(): void {
    this.circleStyles = this.circles.map((_, index) => this.calculateCircleStyle(index));
  }
  calculateCircleStyle(index: number): any {
    if (this.currentBackground.type === 'animated' && this.currentBackground.animatedStyle) {
      const style = this.currentBackground.animatedStyle;
      const colors = style.colors;
      const color = colors[index % colors.length];
  
      // Partikül boyutunu rastgele belirleme
      const sizeValue = Math.random() * (style.maxParticleSize - style.minParticleSize) + style.minParticleSize;
      const size = `${sizeValue}vmin`;
  
      // Başlangıç ve bitiş ölçeklerini rastgele belirleme
      const startScale = Math.random() * (style.maxScale - style.minScale) + style.minScale;
      const endScale = Math.random() * (style.maxScale - style.minScale) + style.minScale;
  
      // Konumlandırma
      const top = `${Math.random() * 100}%`;
      const left = `${Math.random() * 100}%`;
  
      // Animasyon süresi ve gecikmesi
      const durationValue = Math.random() * style.animationDuration + style.animationDuration;
      const duration = `${durationValue}s`;
      const delay = `${-Math.random() * durationValue}s`;
  
      // 3D efekt için transform-origin değerleri
      const transformOriginX = `${Math.random() * 100 - 50}vw`;
      const transformOriginY = `${Math.random() * 100 - 50}vh`;
  
      // Rastgele dönme açısı
      const rotateDegree = Math.random() * 360;
  
      // Custom CSS değişkenleri
      const customProperties = {
        '--start-scale': startScale,
        '--end-scale': endScale,
        '--rotate-degree': `${rotateDegree}deg`
      };
  
      return {
        width: size,
        height: size,
        backgroundColor: color,
        top: top,
        left: left,
        position: 'absolute',
        borderRadius: '50%',
        backfaceVisibility: 'hidden',
        animationName: 'move',
        animationDuration: duration,
        animationTimingFunction: 'linear',
        animationDelay: delay,
        animationIterationCount: 'infinite',
        transformOrigin: `${transformOriginX} ${transformOriginY}`,
        transform: `scale(${startScale})`,
        ...customProperties
      };
    }
    return {};
  }
}