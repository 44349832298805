import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export enum ServiceName {
  SignalR = 'SignalR',
  Menu = 'Menu',
  Config = 'Config',
  Language = "Language",

}

@Injectable({
  providedIn: 'root'
})
export class ServiceReadyStateService {
  private requiredServices: ServiceName[] = [
    ServiceName.SignalR,
    ServiceName.Menu,
    ServiceName.Config,
    ServiceName.Language,
  ]; // Object.values(ServiceName) as ServiceName[]; // all enum values

  private servicesStatus: Map<ServiceName, boolean> = new Map();
  private allServicesReadySubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() {}

  // Belirli bir servisin durumunu ayarlamak için
  setServiceReady(serviceName: ServiceName, isReady: boolean): void {
    this.servicesStatus.set(serviceName, isReady);
    this.checkAllServicesReady();
  }

  // Tüm servislerin durumunu kontrol etmek için
  private checkAllServicesReady(): void {
    const allReady = this.requiredServices.every(
      serviceName => this.servicesStatus.get(serviceName) === true
    );
    this.allServicesReadySubject.next(allReady);
  }

  // Tüm servisler hazır olduğunda abone olunabilecek bir observable
  get allServicesReady$(): Observable<boolean> {
    return this.allServicesReadySubject.asObservable();
  }

  // Belirli bir servisin hazır olup olmadığını kontrol etmek için
  isServiceReady(serviceName: ServiceName): boolean {
    return this.servicesStatus.get(serviceName) || false;
  }
}